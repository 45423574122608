import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { callApiAsync } from "../../utils/apiUtil";
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Button,
    Grid,
    TextField
} from "@mui/material";
export default function UserManagement() {
    const [users, setUsers] = React.useState([]);
    const { instance } = useMsal();
    const [isLoading, setIsLoading] = useState(false);
    const [newUser, setNewUser] = useState({ displayName: "", email: "", password: "", accountEnabled: true });

    useEffect(() => {
        console.log("reloaded");
        setIsLoading(true);
        callApiAsync(instance, null, "GET", `/api/User`)
            .then((response) => response.json())
            .then((data) => {
                setUsers([...data]);
                setIsLoading(false);
                console.log(users);
            });

    }, [instance]);

    const getUsers = async () => {
        setIsLoading(true);
        try {
            const response = await callApiAsync(instance, null, "GET", `/api/User`);
            const data = await response.json();
            setUsers(data);
        } catch (error) {
            console.error("Error fetching users:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await callApiAsync(instance, {
                displayName: newUser.displayName,
                mailNickname: newUser.email.split('@')[0],
                mail: newUser.email,
                passwordProfile: {
                    forceChangePasswordNextSignIn: true,
                    password: newUser.password,
                },
            }, "POST", `/api/user/adduser`);

            if (response.ok) {
                getUsers(); // Refresh the user list after adding a new user
                setNewUser({ displayName: "", email: "", password: "", accountEnabled: true }); // Reset the form
            } else {
                console.error("Error adding user:", await response.json());
            }
        } catch (error) {
            console.error("Error adding user:", error);
        }
    };

    const handleDelete = async (userId) => {
        if (window.confirm("Are you sure you want to delete this user?")) {
            try {
                const response = await callApiAsync(instance, null, "DELETE", `/api/User/${userId}`);
                if (response.ok) {
                    alert("User deleted successfully!");
                    getUsers();
                } else {
                    console.error("Error deleting user:", await response.json());
                }
            } catch (error) {
                console.error("Error deleting user:", error);
            }
        }
    };

    const resetPassword = async (userId) => {
        const modal = document.getElementById("passwordModal");
        const passwordInput = document.getElementById("newPasswordInput");
        const closeBtn = document.querySelector(".close");
        const submitPasswordBtn = document.getElementById("submitPassword");
        const messageContainer = document.getElementById("passwordMessage");

        modal.style.display = "block";

        messageContainer.innerHTML = '';
        messageContainer.classList.remove('success', 'error');

        closeBtn.onclick = function () {
            modal.style.display = "none";
        };

        window.onclick = function (event) {
            if (event.target == modal) {
                modal.style.display = "none";
            }
        };

        submitPasswordBtn.onclick = async function () {
            messageContainer.innerHTML = '';
            messageContainer.classList.remove('success', 'error');
            const newPassword = passwordInput.value;
            if (!newPassword) {
                messageContainer.innerHTML = 'Please enter a valid password';
                messageContainer.classList.add('error');
                return;
            }

            try {
                const response = await callApiAsync(instance, {
                    password: newPassword
                }, "POST", `/api/user/update/${userId}`);

                if (response.ok) {
                    messageContainer.innerHTML = 'Password reset successfully.';
                    messageContainer.classList.add('success');
                } else {
                    const errorData = await response.json();
                    messageContainer.innerHTML = 'Failed to reset password. Please try again.';
                    messageContainer.classList.add('error');
                }
            } catch (error) {
                messageContainer.innerHTML = 'An error occurred while resetting the password.';
                messageContainer.classList.add('error');
            } finally {
                passwordInput.value = '';
            }
        };
    };


    return (
        <div>
            <Typography sx={{ textAlign: 'center', margin: "10px" }} variant="h4" color="#1976D2" fontWeight="500">
                User Management
            </Typography>

            <div id="passwordModal" class="modal">
                <div class="modal-content">
                    <span class="close">&times;</span>
                    <div class="text-content">
                        <Typography sx={{}} variant="h6" color="#1976D2" fontWeight="500">Reset Password</Typography>

                        <Typography variant="h8" fontWeight="700">Please enter a new password (including letters and numbers, e.g., Password123):</Typography>
                        <div class="newPasswordForm">
                            <TextField sx={{ width: '50%' }} label="New Password" variant="outlined" name="newPasswordInput" type="password" id="newPasswordInput" required />
                            <Button variant="contained" color="primary" id="submitPassword">Submit</Button></div>
                        <div id="passwordMessage" class="message"></div>

                    </div>
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <Grid sx={{ justifyContent: 'center' }} container spacing={2} alignItems="center">
                    <Grid item>
                        <TextField
                            label="Display Name"
                            variant="outlined"
                            name="displayName"
                            value={newUser.displayName}
                            onChange={handleInputChange}
                            required
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Email"
                            variant="outlined"
                            name="email"
                            type="email"
                            value={newUser.email}
                            onChange={handleInputChange}
                            required
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Password"
                            variant="outlined"
                            name="password"
                            type="password"
                            value={newUser.password}
                            onChange={handleInputChange}
                            required
                        />
                    </Grid>
                    <Grid item>
                        <Button type="submit" variant="contained" color="primary">
                            Add User
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Display Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Id</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user, key) => (
                        <TableRow key={key}>
                            <TableCell>{user.name}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.id}</TableCell>
                            <TableCell>
                                <Button variant="contained" color="primary" onClick={() => resetPassword(user.id)}>Reset Password</Button>
                            </TableCell>
                            <TableCell>
                                <Button variant="contained" color="error" onClick={() => handleDelete(user.id)}>
                                    Delete
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </div>
    )
}