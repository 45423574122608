import React, { Component, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import BulkProducts from "./components/WarehouseStock/BulkProducts";
import LocationInput from "./components/WarehouseStock/LocationInput";
import ShelfProducts from "./components/WarehouseStock/ShelfProducts";
import OverBulkLimit from "./components/WarehouseStock/OverBulkLimit";
import UserManagement from "./components/Users/UserManagement";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import "./App.css";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import {
    AuthenticatedTemplate,
    MsalProvider,
    UnauthenticatedTemplate,
    useMsalAuthentication,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import { loginRequest } from "./authConfig";
import BottomNav from "./components/BottomNav";
import PointOfSale from "./pages/PointOfSale";
import { LocalizationProvider } from "@mui/x-date-pickers";

const Login = () => {
    const { login } = useMsalAuthentication(
        InteractionType.Redirect,
        loginRequest
    );
    useEffect(() => {
        login();
    }, [login]);
    return <></>;
};

const Pages = () => {
    return (
        <>
            <UnauthenticatedTemplate>
                <Login />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <Routes>
                    <Route path="/bulkproducts/:bulkId" element={<BulkProducts />} />
                    <Route path="/overbulklimit" element={<OverBulkLimit />} />
                    <Route path="/shelfproducts/:shelfId" element={<ShelfProducts />} />
                    <Route path="/" element={<LocationInput />} />
                    <Route path="/PointOfSale" element={<PointOfSale />} />
                    <Route path="/UserManagement" element={<UserManagement />} />
                </Routes>
                <BottomNav></BottomNav>
            </AuthenticatedTemplate>
        </>
    );
};

const App = ({ instance }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MsalProvider instance={instance}>
                <Pages></Pages>
            </MsalProvider>
        </LocalizationProvider>
    );
};

export default App;
