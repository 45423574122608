import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { Paper } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { callApiAsync } from "../utils/apiUtil";

export default function BottomNav() {
    const { instance } = useMsal();
    const [settings, setSettings] = React.useState({});

    React.useEffect(() => {
        const getSettings = async () => {
            let response = await callApiAsync(instance, null, "GET", "/api/settings");
            if (response.ok) {
                setSettings(await response.json());
            }
        };

        getSettings();
    }, [instance, setSettings]);

    const handleLogoutOnClick = () => instance.logout();

    return (
        <Paper
            elevation={5}
            sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: "#f6f6f6",
            }}
        >
            <BottomNavigation showLabels>
                <BottomNavigationAction label="Store Home" href={settings.storeUrl} />
                <BottomNavigationAction
                    label="Store Admin"
                    href={`${settings.storeUrl}Admin`}
                />
                <BottomNavigationAction label="Point Of Sale" href="/PointOfSale" />
                <BottomNavigationAction label="Warehouse Stock Management" href="/" />
                <BottomNavigationAction label="User Management" href="/UserManagement" />
        <BottomNavigationAction
                    label="Log Out"
                    onClick={() => handleLogoutOnClick()}
                />
            </BottomNavigation>
        </Paper>
    );
}
